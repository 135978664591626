var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"ml-12"},[_vm._m(0),_c('div',{staticClass:"bg-white mt-8 rounded-lg overflow-hidden big-shadow"},[_c('div',{staticClass:"mt-8 mb-5 mx-20 justify-center flex flex-row items-start"},[_c('div',{staticClass:"flex-column w-8/12 mt-32"},[_c('div',{staticClass:"w-6/12 text-center ml-32"},[_vm._m(1),_vm._m(2),_c('div',{staticClass:"flex flex-row gap-8 w-10/12 justify-center mx-auto mt-16"},[_c('trac-input'),_c('trac-input'),_c('trac-input'),_c('trac-input')],1),_vm._m(3),_c('div',{staticClass:"w-8/12 mt-24 mx-auto"},[_c('trac-button',{staticClass:"uppercase"},[_vm._v("Enable Security")])],1),_vm._m(4)])]),_c('div',{staticClass:"flex-column w-412 mt-32 mb-20"},[_c('div',{staticClass:"grid grid-cols-2 gap-5 gap-x-12"},_vm._l((_vm.features),function(feature,i){return _c('div',{key:i,staticClass:"bg-accentLight px-5 pt-5 pb-10 h-40 rounded-lg overflow-hidden flex-column"},[_vm._m(5,true),_c('div',{staticClass:"mt-8 w-10/12"},[_c('span',{staticClass:"font-normal text-base"},[_vm._v(_vm._s(feature))])])])}),0)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-12"},[_c('div',{staticClass:"font-normal text-sm flex flex-row gap-2"},[_c('img',{attrs:{"src":require("../../assets/svg/Path.svg")}}),_c('a',{attrs:{"href":"#"}},[_vm._v("Open Business Account")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{staticClass:"font-semibold text-2xl mx-auto"},[_vm._v("Confirm your pin")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"font-normal text-xs text-primaryBlue mt-3"},[_c('span',[_vm._v("Create a 4 digit pin code to protect your account against unauthorized access.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-right mr-8"},[_c('span',{staticClass:"text-primaryBlue text-xs font-normal"},[_vm._v("Clear")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-8/12 mt-6 mx-auto"},[_c('span',{staticClass:"font-bold text-xs text-primaryBlue uppercase"},[_vm._v("Cancel")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../../assets/svg/gift.svg")}})])
}]

export { render, staticRenderFns }